@use './colors.scss' as *;

$md-btn-padding: 5px;
$md-top-layer-z-index: 10000;

@mixin buttonStyle($bg-color, $fg-color, $outline-color) {
    background-color: $bg-color;
    color: $fg-color;
    outline: 2px solid $outline-color;

    transition: .1s ease-in;

    &:hover {
        background-color: lighten($color: $bg-color, $amount: 10%);
        outline-color: lighten($color: $outline-color, $amount: 10%);
        color: lighten($color: $fg-color, $amount: 10%);
    }

    &:active {
        transform: scale(.98);
    }

    &:disabled, &[disbaled] {
        color: rgba(white, .5);
        outline-color: rgba($md-grey-color, .5);
        background-color: rgba($md-grey-color, .5);
        cursor: not-allowed;
    }
}

button.md-btn, button.md-btn:active, button.md-btn:focus:not(:focus-visible) {
    padding: $md-btn-padding;
    min-width: 100px;
    min-height: 40px;
    border: none;
    border-radius: 8px;
    outline: none;
}

button.md-btn-primary, button.md-btn-primary:focus:not(:focus-visible) {
    @include buttonStyle($md-color-primary, white, $md-color-primary);
}

button.md-btn-secondary, button.md-btn-secondary:focus:not(:focus-visible) {
    @include buttonStyle(transparent, $md-grey-color, $md-grey-color);
}

button.md-btn-important, button.md-btn-important:focus:not(:focus-visible) {
    @include buttonStyle($md-color-important, white, $md-color-important)
}