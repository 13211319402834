@use '../../../scss/common.scss' as *;

$modal-container-padding: 30px;
$modal-border-radius: 10px;

$modal-backrop-color: rgba(10, 10, 10, .5);

$modal-title-font-size: 1.2rem;

.modal-root-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $modal-backrop-color;
    overflow-y: auto;
    z-index: $md-top-layer-z-index;
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: $modal-container-padding;
    border-radius: $modal-border-radius;
    min-width: 60%;
    max-width: 80%;
    background-color: #273142;

    .modal-title {
        padding-bottom: $modal-container-padding;

        font-size: $modal-title-font-size;
        color: white;
    }

    .modal-actions-section {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding-top: $modal-container-padding;
    }
}