$side_padding: 20px;
$confirm_color: #1bb99a;
$cancel_color: #dc3545;
$callBack_color: #007bff;
$pending_color: #a436ac;

.admin_addAgent {
  display: flex;
  // align-items: center;
  flex-direction: column;

  padding: 10px;
  & > h2 {
    align-items: flex-end;
    width: 100%;
  }
  .admin_addAgent_addForm {
    width: 100%;
  }
  .admin_addAgent_agentListTable {
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    width: 100%;
    overflow-x: scroll;
    .admin_addAgent_agentListTable_top {
      align-self: flex-start;
      margin-bottom: 20px;
    }
    & > h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      align-self: flex-start;
    }
    table {
      width: 100%;
      thead {
        background-color: #323d4e;
        tr {
          th {
            font-weight: normal;
            font-size: 18px;
            padding: 10px;
            padding-left: $side_padding;
            padding-right: $side_padding;
            &:nth-child(5) {
              width: 200px;
            }
          }
        }
      }
      tbody {
        tr {
          border: 1px solid #444444;
          background-color: #273142;
          &:hover {
            background-color: lighten(#273142, 10%);
          }
          td {
            padding: 10px;
            padding-left: $side_padding;
            padding-right: $side_padding;
          }
          .agentListaTable_statsColumn {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 200px;
            & > figure {
              & > figcaption {
                padding-top: 20px;
                & > mark {
                  background-color: #e77b0ef5;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.button_add_agent {
  padding: 5.5px;
  background: var(--bdgclInp);
  cursor: pointer;
  margin: 0 5px;
  border-radius: 6px;
  display: inline-flex;
  float: right;
  justify-content: center;
}

.button_add_agent:hover {
  padding: 5.5px;
  background: var(--bdgclInp);
  cursor: pointer;
  margin: 0 5px;
  border-radius: 6px;
  float: right;
  width: 200px;
  height: 50px;
}

.button_search {
  width: 100px;
  height: 40px;
  padding: 8px;
  color: #fff;
  border-radius: 7px;
  margin-right: 10px;
  border: none;
  background-color: #007bff;
  &:hover {
    background-color: lighten(#007bff, 10%);
  }
}

.refreshButton {
  width: 100px;
  height: 40px;
  padding: 8px;
  color: #fff;
  border-radius: 7px;
  border: none;
  background-color: #a6a57a;
  &:hover {
    background-color: lighten(#a6a57a, 10%);
  }
}

.reactSelect,
.reactSelect-status {
  width: 290px;
  color: #000;
  margin-bottom: 10px;
}
.reactSelect-status {
  width: 290px;
  .css-1hwfws3 {
    max-height: 50px;
  }
}
.admin_ordersPage_actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .actions_filter {
    width: 40%;
    min-width: 290px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  form {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    // flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    & > article {
      display: flex;

      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 80%;
      .filterInput {
        width: 290px;
        height: 51px;
        background-color: #fff !important;
        border-radius: 5px;
        padding-left: 10px;
        &::placeholder {
          color: #000;
        }
      }
      input {
        margin-bottom: 10px;
      }
      input,
      .form-control {
        background: transparent !important;
        color: #000 !important;
      }
    }
  }
}
