@use '../../../scss/mixins.scss' as *;
@use '../../../scss/colors.scss' as *;

$drop-down-vertical-padding: .7rem;
$drop-down-horizontal-padding: .5rem;
$drop-down-border-radius: 10px;

.drop-down-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $drop-down-vertical-padding $drop-down-horizontal-padding;
    background-color: transparent;
    border: none;
    border-radius: $drop-down-border-radius;
    cursor: pointer;
    color: $md-grey-color;

    @include commonOutlineEffect(2px, $md-grey-color, $md-color-primary);

    .drop-down-options {
        width: 100%;
        max-height: 50vh;
        overflow-y: auto;

        position: absolute;
        top: 100%;
        left: 0;

        &.collapsed {
            display: none;
        }

        .drop-down-option {
            width: 100%;
            padding: 1rem;

            background-color: white;
            color: rgb(50, 50, 50);

            &:hover {
                background-color: darken($color: white, $amount: 10%);
            }
        }

        .no-options, .no-options:hover {
            background-color: $md-grey-color;
            color: darken($color: white, $amount: 5%);
            cursor: not-allowed;
        }
    }
}